import { gql } from '@apollo/client';
import { REVIEW_FRAGMENT } from './review-fragments';
import { EXTRA_INFO, EXTRA_RESERVE_INFO } from './extra-fragments';
import { SPOT_DETAIL_INFO_SHORT } from './spot-fragments';

export const RESERVATION_REVIEWS_FRAGMENT = gql`
    fragment ReviewList on Reservation {
        hostReviews {
            ...Review
        }
        reviews {
            ...Review
        }
    }
    ${REVIEW_FRAGMENT}
`;

export const SHORT_RESERVATION_FRAGMENT = gql`
    fragment ShortReservation on Reservation {
        id
        spot {
            ...SpotDetailInfoShort
            extras {
                ...ExtraInfo
            }
            host {
                id
                firstname
                lastInitial
                avatarAws
                about
            }
            credits {
                available
                inviteTotal
                membershipped
                total
            }
            maximumDogsAllowed
            timezone
            fullAddress
            timezone
            spotUrl
        }
        user {
            id
            about
            avatarAws
            activeSniffpass
            createdAt
            firstname
            lastInitial
            rating
            reviewsCount
        }
        guests {
            id
            avatarAws
            nameAndInitial
        }
        discount {
            amount
            value
        }
        extras {
            ...ExtraReserveInfo
        }
        date
        datePart
        time
        length
        quantity
        price
        note
        hostNote
        guestNote
        hostRefund
        status
        membershipped
        netToHost
        totalDue
        creditsDue
        discountDue
        sniffpassDiscount
        membershipCreditsDue
        sniffpassCreditsDue
        sniffspotFee
        stripeFee
        confirmedAt
        previuosVisitsFromGuest
        createdAt
        firstReservationFromGuest
        ...ReviewList
    }
    ${SPOT_DETAIL_INFO_SHORT}
    ${RESERVATION_REVIEWS_FRAGMENT}
    ${EXTRA_RESERVE_INFO}
    ${EXTRA_INFO}
`;

export const SHORT_RESERVATION_WITH_DOGS_FRAGMENT = gql`
    fragment ShortReservationWithDogs on Reservation {
        id
        spot {
            ...SpotDetailInfoShort
            extras {
                ...ExtraInfo
            }
            host {
                id
                firstname
                lastInitial
                avatarAws
                about
            }
            credits {
                available
                inviteTotal
                membershipped
                total
            }
            maximumDogsAllowed
            timezone
            fullAddress
            timezone
            spotUrl
        }
        user {
            id
            about
            avatarAws
            activeSniffpass
            createdAt
            firstname
            lastInitial
            rating
            reviewsCount
            dogs {
                id
                photos {
                    id
                    imageAws
                }
            }
        }
        guests {
            id
            avatarAws
            nameAndInitial
        }
        discount {
            amount
            value
        }
        extras {
            ...ExtraReserveInfo
        }
        date
        datePart
        time
        length
        quantity
        price
        note
        hostNote
        guestNote
        hostRefund
        status
        membershipped
        netToHost
        totalDue
        creditsDue
        discountDue
        sniffpassDiscount
        membershipCreditsDue
        sniffpassCreditsDue
        sniffspotFee
        stripeFee
        confirmedAt
        previuosVisitsFromGuest
        createdAt
        firstReservationFromGuest
        ...ReviewList
    }
    ${SPOT_DETAIL_INFO_SHORT}
    ${RESERVATION_REVIEWS_FRAGMENT}
    ${EXTRA_RESERVE_INFO}
    ${EXTRA_INFO}
`;

export const RESERVATION_FRAGMENT = gql`
    fragment Reservation on Reservation {
        ...ShortReservation
        spot {
            id
            street
            zip
            muted
            country
            price
            rules
            latitude
            longitude
            instantBook
            instructions
            timezone
            spotUrl
            offerSniffpass
        }
    }
    ${SHORT_RESERVATION_FRAGMENT}
`;

export const RESERVATION_VISIT_FRAGMENT = gql`
    fragment ReservationVisit on Reservation {
        id
        spot {
            id
            allPhotos
            street
            price
            rules
            realLatitude
            realLongitude
            instructions
            spotUrl
            timezone
            fullAddress
            timezone
            hostPresencePreference
            host {
                id
                firstname
                lastInitial
                avatarAws
                about
                aboutHost
                createdAt
            }
            extras {
                ...ExtraInfo
            }
            credits {
                available
            }
            accessPhotos {
                id
                imageAws
                caption
            }
            ...SpotDetailInfoShort
        }
        discount {
            amount
            value
        }
        extras {
            ...ExtraReserveInfo
        }
        user {
            id
            about
            avatarAws
            createdAt
            firstname
            lastInitial
            rating
            reviewsCount
        }
        guests {
            id
            avatarAws
            nameAndInitial
        }
        date
        datePart
        time
        length
        quantity
        price
        note
        hostNote
        guestNote
        hostRefund
        status
        membershipped
        totalDue
        creditsDue
        discountDue
        sniffpassDiscount
        membershipCreditsDue
        sniffpassCreditsDue
        previuosVisitsFromGuest
        firstReservationFromGuest
        firstReservationOnSpot
        createdAt
        ...ReviewList
    }
    ${SPOT_DETAIL_INFO_SHORT}
    ${RESERVATION_REVIEWS_FRAGMENT}
    ${EXTRA_RESERVE_INFO}
    ${EXTRA_INFO}
`;
