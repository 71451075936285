import { gql } from '@apollo/client';
import { SPOT_ALERT_INFO, USER_LOCATION_INFO, USER_LONG_INFO } from './user-fragments';

export const SET_LOCATION_MUTATION = gql`
    mutation updateUser($id: ID!, $latitude: Float, $longitude: Float, $state: String, $city: String) {
        updateUser(id: $id, latitude: $latitude, longitude: $longitude, state: $state, city: $city) {
            id
            ...UserLocationInfo
        }
    }
    ${USER_LOCATION_INFO}
`;

export const GET_INVITE_URL = gql`
    mutation getInviteUrl($inviteType: InvitedAs) {
        getInviteUrl(inviteType: $inviteType)
    }
`;

export const USER_INVITE_DATE_MUTATION = gql`
    mutation updateUser($id: ID!, $showInviteAt: ISO8601DateTime) {
        updateUser(id: $id, showInviteAt: $showInviteAt) {
            showInviteAt
        }
    }
`;

export const UPDATE_SPOT_ALERT_MUTATION = gql`
    mutation updateSpotAlert(
        $radius: Int
        $enclosureType: AlertEnclosure
        $minimumSize: Float
        $dogsAllowed: Boolean
        $active: Boolean
        $latitude: Float
        $longitude: Float
    ) {
        updateSpotAlert(
            latitude: $latitude
            longitude: $longitude
            radius: $radius
            enclosureType: $enclosureType
            minimumSize: $minimumSize
            dogsAllowed: $dogsAllowed
            active: $active
        ) {
            ...SpotAlertInfo
        }
    }
    ${SPOT_ALERT_INFO}
`;

export const UPDATE_USER_MUTATION = gql`
    mutation updateUser(
        $id: ID!
        $firstname: String
        $lastname: String
        $phoneNumber: String
        $about: String
        $aboutHost: String
        $aboutHostMore: String
        $email: String
        $firstHear: String
        $firstHearSocialMedia: String
        $referralCode: String
        $tosAgree: Boolean
        $vaccinated: Boolean
        $hostWelcomeMessageBanner: Boolean
        $hostWelcomeMessageText: String
        $hostWelcomeMessagesOn: Boolean
        $hostThankYouMessageText: String
        $hostThankYouMessagesOn: Boolean
        $hostMode: Boolean
        $avatarAws: Upload
        $gender: String
        $birthAt: ISO8601Date
        $race: String
        $instagramUsername: String
        $payoutAccountId: String
        $hostRulesAccepted: Boolean
        $rulesAccepted: Boolean
        $showGuestBannerApp: Boolean
        $showGuestBannerFacebook: Boolean
        $showGuestBannerPhoneNumber: Boolean
        $showHostBannerBookingsAutoConfirmed: Boolean
        $showHostBannerDownloadApp: Boolean
        $showHostBannerFacebook: Boolean
        $showHostBannerMembership: Boolean
        $showHostBannerOptimizeSpotSettings: Boolean
        $showHostBannerRisingSpot: Boolean
        $showHostBannerSetupCalendar: Boolean
        $showHostBannerTopSpot: Boolean
        $showHostBannerWelcomeMessage: Boolean
        $testGroups: [String!]
    ) {
        updateUser(
            id: $id
            firstname: $firstname
            lastname: $lastname
            email: $email
            phoneNumber: $phoneNumber
            about: $about
            aboutHost: $aboutHost
            aboutHostMore: $aboutHostMore
            firstHear: $firstHear
            firstHearSocialMedia: $firstHearSocialMedia
            avatarAws: $avatarAws
            tosAgree: $tosAgree
            vaccinated: $vaccinated
            hostWelcomeMessageBanner: $hostWelcomeMessageBanner
            hostWelcomeMessageText: $hostWelcomeMessageText
            hostWelcomeMessagesOn: $hostWelcomeMessagesOn
            hostThankYouMessageText: $hostThankYouMessageText
            hostThankYouMessagesOn: $hostThankYouMessagesOn
            referralCode: $referralCode
            hostMode: $hostMode
            gender: $gender
            birthAt: $birthAt
            race: $race
            instagramUsername: $instagramUsername
            payoutAccountId: $payoutAccountId
            hostRulesAccepted: $hostRulesAccepted
            rulesAccepted: $rulesAccepted
            showGuestBannerApp: $showGuestBannerApp
            showGuestBannerFacebook: $showGuestBannerFacebook
            showGuestBannerPhoneNumber: $showGuestBannerPhoneNumber
            showHostBannerBookingsAutoConfirmed: $showHostBannerBookingsAutoConfirmed
            showHostBannerDownloadApp: $showHostBannerDownloadApp
            showHostBannerFacebook: $showHostBannerFacebook
            showHostBannerMembership: $showHostBannerMembership
            showHostBannerOptimizeSpotSettings: $showHostBannerOptimizeSpotSettings
            showHostBannerRisingSpot: $showHostBannerRisingSpot
            showHostBannerSetupCalendar: $showHostBannerSetupCalendar
            showHostBannerTopSpot: $showHostBannerTopSpot
            showHostBannerWelcomeMessage: $showHostBannerWelcomeMessage
            testGroups: $testGroups
        ) {
            ...UserLongInfo
        }
    }
    ${USER_LONG_INFO}
`;

export const DELETE_USER_MUTATION = gql`
    mutation deleteUser {
        deleteUser {
            id
        }
    }
`;

export const REQ_MEMBERSHIP_MUTATION = gql`
    mutation requestMembership($spotId: ID!) {
        requestMembership(spotId: $spotId)
    }
`;

export const VIEW_HOMEPAGE_MUTATION = gql`
    mutation viewPhotoHomePage($testGroup: Boolean!) {
        viewPhotoHomePage(testGroup: $testGroup)
    }
`;

export const VIEW_HOMEPAGE_V2_MUTATION = gql`
    mutation viewHomePageV2($testGroup: Boolean!) {
        viewHomePageV2(testGroup: $testGroup)
    }
`;

export const VIEW_HOMEPAGE_V3_MUTATION = gql`
    mutation viewHomeSearchV3($testGroup: Boolean!) {
        viewHomeSearchV3(testGroup: $testGroup)
    }
`;

export const VIEW_HOME_SEARCH_MUTATION = gql`
    mutation viewSearchPageNew($testGroup: Boolean!) {
        viewSearchPageNew(testGroup: $testGroup)
    }
`;

export const VIEW_SEARCHPAGE_MUTATION = gql`
    mutation viewSearchPage($testGroup: Boolean!) {
        viewSearchPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEARCHPAGE_FILTERS_MUTATION = gql`
    mutation viewSearchPageFilters($testGroup: Boolean!) {
        viewSearchPageFilters(testGroup: $testGroup)
    }
`;

export const VIEW_HOSTPAGE_MUTATION = gql`
    mutation viewHostPage($testGroup: Boolean!) {
        viewHostPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEOCITY_MUTATION = gql`
    mutation viewSeoCityPage($testGroup: Boolean!) {
        viewSeoCityPage(testGroup: $testGroup)
    }
`;

export const VIEW_HOST_SIGNUP_MUTATION = gql`
    mutation viewHostSignupPage($testGroup: Boolean!) {
        viewHostSignupPage(testGroup: $testGroup)
    }
`;

export const VIEW_QUICK_BOOK_MUTATION = gql`
    mutation viewQuickBookPage($testGroup: Boolean!) {
        viewQuickBookPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEARCH_SIGNUP_MUTATION = gql`
    mutation viewSearchSignUpPage($testGroup: Boolean!) {
        viewSearchSignUpPage(testGroup: $testGroup)
    }
`;

export const VIEW_SEO_FRIENDLY_SIGNUP_MUTATION = gql`
    mutation viewSeoFriendlySignUp($testGroup: Boolean!) {
        viewSeoFriendlySignUp(testGroup: $testGroup)
    }
`;

export const VIEW_CALENDAR_SIGNIN_MUTATION = gql`
    mutation viewCalendarWithSignIn($testGroup: Boolean!) {
        viewCalendarWithSignIn(testGroup: $testGroup)
    }
`;

export const VIEW_LISTING_AA = gql`
    mutation viewFrontTest($testGroup: Boolean!) {
        frontTest(testGroup: $testGroup)
    }
`;

export const SIGNUP_HOST = gql`
    mutation signUp(
        $email: String!
        $firstname: String!
        $lastname: String!
        $password: String!
        $phoneNumber: String
        $uuid: String
        $invitedAs: InvitedAs
        $invitedByUuid: String
        $utmSource: String
        $utmMedium: String
        $utmCampaign: String
        $createdFrom: CreatedFrom
        $createdPlatform: CreatedPlatform
    ) {
        signUp(
            email: $email
            firstname: $firstname
            lastname: $lastname
            password: $password
            phoneNumber: $phoneNumber
            uuid: $uuid
            invitedAs: $invitedAs
            invitedByUuid: $invitedByUuid
            utmSource: $utmSource
            utmMedium: $utmMedium
            utmCampaign: $utmCampaign
            createdFrom: $createdFrom
            createdPlatform: $createdPlatform
        ) {
            id
            email
            phoneNumber
            testGroups
        }
    }
`;

export const REMOVE_GOOGLE_CALENDAR = gql`
    mutation removeGoogleCalendar {
        removeGoogleCalendar
    }
`;
